@tailwind base;
@tailwind components;
@tailwind utilities;


/* .loader {
    --path: #2F3545;
    --dot: #5628EE;
  }
  
  .loader svg rect,
  .loader svg polygon,
  .loader svg circle {
    fill: none;
    stroke: var(--path);
    stroke-width: 10px;
    stroke-linejoin: round;
    stroke-linecap: round;
  } */